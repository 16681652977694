<template>
  <v-container class="contenedor-main">
    <v-row>
      <tool-bar-nav
        :pageRow="{ value: pageLengthPer, type: 'trabajosDHeigth' }"
        :editColsBottom="{ badgeText: 'Editar cols' }"
        :dataColumns="{
          colsShow: columnasMostrar,
          colsList: listaColumnas,
          endpointEdit: 'venta_trabajo',
          materia: null,
          periodo: null,
        }"
        :refreshBottom="{ badgeText: 'Reestablecer cols' }"
        :copyRowBottom="{ badgeText: 'Copiar trabajo' }"
        :exportBottom="{
          badgeText: 'Exportar tabla',
          nameDoc: 'Trabajos',
        }"
        :histBottom="{ show: rigthClick, data: historial }"
        :colorBottom="{ show: rigthClick }"
        :textoActionToolBar="{ text: textoActionToolBar }"
        :selectedRow="onSelected"
        :grid="{ idGrid: $refs.grid }"
        v-on:toggle="eventosTrabajos($event)"
        v-on:texto-tool="eventosTextTool($event)"
        v-on:color-item="eventosColor($event, asignarColor)"
      >
      </tool-bar-nav>
      <v-container class="contenedor-tabla" v-show="mostrarTable == true">
        <vue-excel-editor
          v-if="mt"
          v-show="mostrarTable == true"
          ref="grid"
          class="table"
          :page="pageLengthPer"
          v-model="jsondata"
          @select="onSelect"
          :cell-style="metodoColorBack"
          filter-row
          :localized-label="myLabels"
          remember
          @cell-click="position"
          :enterToSouth="enterAbajo"
          v-on:toggle-table="activeRigthClick($event)"
          @cell-focus="getLabelColor"
        >
          <vue-excel-column
            field="ID"
            label="ID"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Fecha"
            label="Fecha"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Línea"
            label="Línea"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Materia"
            label="Materia"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Fase de trabajo"
            label="Fase de trabajo"
            type="select"
            :validate="valid"
          />
          <vue-excel-column
            field="Tutor"
            label="Tutor"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="LINK DRIVE"
            label="LINK DRIVE"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Notas al Encargado"
            label="Notas al Encargado"
            type="string"
            :validate="valid"
          />
          <vue-excel-column
            field="Archivos necesarios"
            label="Archivos necesarios"
            type="select"
            :change="openCrud"
            :options="['Ver archivos']"
          />
          <vue-excel-column
            field="ANOTACIONES"
            label="ANOTACIONES"
            type="string"
            :change="addAnotacion"
          />
          <vue-excel-column
            field="Estado"
            label="Estado"
            :change="cambioEstado"
            type="select"
            :options="[
              'MAL MIGRADO',
              'NO INGRESA CORREO',
              'NO INGRESA PLATAFORMA',
              'NO LLEGA PROUNAD',
              'VERIFICACION CORREO',
              'FINALIZADO CORREO',
              'FINALIZADO',
              'NUEVO',
              'OK',
            ]"
          />
          <vue-excel-column
            field="Subida de trabajo"
            label="Subida de trabajo"
            :change="openCrud"
            type="select"
            :options="['Subir el trabajo']"
          />
          <vue-excel-column
            field="Correo"
            label="Correo"
            type="string"
            :validate="valid"
          />
        </vue-excel-editor>

        <v-dialog v-if="dialog" v-model="dialog" scrollable max-width="50%">
          <crud-archivos
            v-on:toggle="toggleArchivos($event)"
            :title="'Ver los archivos cargados de la venta'"
            :subtitle="'Archivos de la venta cargados por el admin'"
            :textVer="'Ver link '"
            :listData="listaArchivos"
            :cargar="false"
            :eliminar="false"
            tipo="ver"
            :info="null"
          >
          </crud-archivos>
        </v-dialog>

        <v-dialog v-model="dialogT" v-if="dialogT" scrollable max-width="50%">
          <crud-archivos
            v-on:toggle="toggleArchivos($event)"
            :title="'Ver los archivos cargados'"
            :subtitle="'Cargar archivos del trabajo'"
            :textVer="'Ver link '"
            :listData="listaArchivosT"
            :cargar="true"
            :eliminar="true"
            tipo="subeTrabajo"
            :info="trabajo"
          >
          </crud-archivos>
        </v-dialog>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { host } from "./Tools/variables";
import axios from "axios";
import ToolBarNav from "./component/ToolBarNav.vue";
import infoSelected from "./Tools/itemSelected";

import tablesMixin from "../mixins/tablas";

export default {
  mixins: [tablesMixin],
  components: {
    CrudArchivos: () =>
      import(
        /* webpackChunkName: "CrudArchivos"  */ "./component/CrudArchivos.vue"
      ),
    ToolBarNav,
  },
  data() {
    return {
      mostrarTable: false,
      textoActionToolBar: [],
      editarColumnas: false,
      listaColumnas: [],
      columnasMostrar: [],
      pageLengthPer: this.$store.state.trabajosDHeigth,
      jsondata: [],
      dialogT: false,
      dialog: false,
      rowWA: null,
      listaArchivos: [],
      listaArchivosT: [],
      id: null,
      dataLink: [],
      dataLinkT: [],
      onSelected: null,
      buscando: false,
      mt: false,
      trabajo: {
        id: null,
      },
      // click derecho
      rigthClick: false,
      // color
      asignarColor: {
        name: null,
        color: null,
        idItem: null,
      },
      // tiene personalización de cols?
      haveCellsColor: true,
      // hitorial
      historial: {
        nombreCol: null,
        id: null,
      },
    };
  },
  async created() {
    await this.cargueColors();
    this.initialize();
  },

  mounted() {
    document.title = "Trabajos Docente";
  },
  methods: {
    initialize() {
      this.buscando = true;
      this.jsondata = [];
      this.mostrarTable = false;
      this.mt = false;
      this.listaColumnas = [];
      this.columnasMostrar = [];
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "listar_trabajos");
          axios.post(host + "/ventas", fd).then((result) => {
            let data = result.data.lista;
            if (result.data.text == "OK") {
              this.buscando = false;
              for (let i = 0; i < data.length; i++) {
                var vA = Intl.NumberFormat("de-DE").format(
                  data[i]["Valor acordado"]
                );
                data[i]["Correo"] =
                  data[i]["Correo"] == "null" ? "-" : data[i]["Correo"];
                var vR = Intl.NumberFormat().format(data[i]["Valor Recaudado"]);
                data[i]["Valor acordado"] = vA;
                data[i]["Valor Recaudado"] = vR;
                data[i]["Estado"] =
                  data[i]["Estado"] == "null"
                    ? "-"
                    : data[i]["Estado"] == "undefined"
                    ? "-"
                    : data[i]["Estado"];
                data[i]["Fase de trabajo"] =
                  data[i]["Fase de trabajo"] == "null"
                    ? "-"
                    : data[i]["Fase de trabajo"] == "undefined"
                    ? "-"
                    : data[i]["Fase de trabajo"];
                data[i]["Link del trabajo"] =
                  data[i]["Link del trabajo"] == "null" ? "-" : "";
                this.dataLink.push({
                  id: data[i]["_id"],
                  data: data[i]["archivos"],
                });
                this.dataLinkT.push({
                  id: data[i]["_id"],
                  data: data[i]["archivosTrabajo"],
                });
                data[i]["Archivos necesarios"] =
                  data[i]["Archivos necesarios"] == "null" ? "-" : "";
                data[i]["Notas al Encargado"] =
                  data[i]["Notas al Encargado"] == "null"
                    ? "-"
                    : data[i]["Notas al Encargado"];
                data[i]["Fecha"] = this.$moment(data[i]["Fecha"]).format(
                  "YYYY/MM/DD - HH:mm:ss"
                );
              }
              this.jsondata = data;
              this.mt = true;
              if (this.jsondata.length == 0) {
                this.$alert("No existen ventas");
              } else {
                this.mostrarTable = true;
                this.listaColumnas = result.data.visibilidad;
                for (var k = 0; k < this.listaColumnas.length; k++) {
                  if (this.listaColumnas[k]["valor"] == true) {
                    this.columnasMostrar.push(this.listaColumnas[k]);
                  }
                }
                this.cargueCol(["Pagos", "WhatsApp"]);
              }
            }
          });
          this.mt = true;
        })
        .catch(function (error) {
          this.buscando = false;
          this.$alert("Error " + error.message);
        });
    },

    onSelect() {
      this.onSelected = infoSelected(this.$refs.grid, "_id");
      this.rigthClick = false;
    },

    position(rowPos) {
      this.rowWA = rowPos.rowPos;
    },

    openCrud(newVal, oldVal, row) {
      this.$refs.grid.inputBoxBlur();
      this.nameCard = row.nombre;
      if (newVal.trim() == "Ver archivos") {
        const resObject = this.dataLink.find((item) => item.id === row._id);
        this.listaArchivos = resObject.data;
        this.dialog = true;
      } else {
        const resObject = this.dataLinkT.find((item) => item.id === row._id);
        this.listaArchivosT = resObject.data;
        this.trabajo = row;
        this.trabajo.id = row._id;
        this.dialogT = true;
      }
    },

    cambioEstado(newVal, oldVal, row) {
      var envia = false;
      if (oldVal.trim() == "OK") {
        envia = true;
      }
      if (oldVal.trim() == "NUEVO" && newVal.trim() == "OK") {
        envia = true;
      }
      if (envia) {
        this.textoActionToolBar.push("Guardando estadoU " + '"' + newVal + '"');
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const fd = new FormData();
            fd.append("token", idToken);
            fd.append("tipo", "editar_estado_trabajo");
            fd.append("estado", newVal.trim());
            fd.append("ventaId", row._id);
            axios.post(host + "/ventas", fd).then((result) => {
              var rem = this.textoActionToolBar.indexOf(
                "Guardando estadoU " + '"' + newVal + '"'
              );
              let data = result.data;
              if (data.text == "OK") {
                this.textoActionToolBar.splice(rem, 1);
                this.initialize();
                this.jsondata = [];
              } else {
                this.textoActionToolBar.splice(rem, 1);
                this.textoActionToolBar.push(
                  "Error al guardar " + '"' + newVal + '"'
                );
                this.$alert("No se pudo realizar el ajuste del estado");
              }
            });
          })
          .catch(function (error) {
            this.$alert("Error " + error.message);
          });
      } else {
        return false;
      }
    },

    addAnotacion(newVal, oldVal, row, field) {
      console.log(field, oldVal);
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("ventaId", row._id);
          fd.append("anotacion", newVal.trim());
          axios.post(host + "/editarObservacionTrabajo", fd).then((result) => {
            let data = result.data;
            if (data.text != "OK") {
              this.$alert("No se pudo agregar la anotación");
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    metodoColor(row, col) {
      var verde = ["ENVIADO A CLIENTE", "FINALIZADO CORREO"];
      var rojos = ["CANCELADO", "NUNCA ENVIADO", "MAL MIGRADO"];
      var moradoNuevo = ["NUEVO"];
      var azul = ["FINALIZADO"];
      switch (col.name) {
        case "Estado":
          var rowItemEstado = row["Estado"].trim();
          if (rojos.includes(rowItemEstado)) {
            return {
              "background-color": "#FF0000",
            };
          }
          if (verde.includes(rowItemEstado)) {
            return {
              "background-color": "#34a853",
            };
          }
          if (moradoNuevo.includes(rowItemEstado)) {
            return {
              "background-color": "#c27ba0",
            };
          }
          if (azul.includes(rowItemEstado)) {
            return {
              "background-color": "#4285f4",
            };
          }
          break;
        default:
          break;
      }
      return {};
    },

    //  EVENTOS
    eventosTrabajos(d) {
      if (d == "refresh" || d == "edit") {
        this.initialize();
      }
      if (d == "clearText") {
        this.textoActionToolBar = [];
      }
    },

    toggleArchivos(event) {
      if (event != "cerrar") {
        this.listaArchivosT.splice(event, 1);
      }
      if (event == "cerrar") {
        this.dialog = false;
        this.dialogT = false;
      }
    },
  },
};
</script>
